// @flow
import * as React from 'react'

import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

import { Currency } from 'i18n/NumberFormatter'
import SidebarHeader from '../Header'
import VideoThumbnail from 'components/VideoThumbnail'
import MediaQuery from 'react-responsive'
import { Store } from 'Store'

function Video (props) {
    const { t, theme } = props
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency('CAD', state.locale)

    return (
        <div className={styles.container}>
            <MediaQuery minWidth={1201}>
                <SidebarHeader title={state.campaign.client.name} />
            </MediaQuery>

            <VideoThumbnail
                background={theme.background}
                title={t('title', { name: state.lead.fields.firstName })}
                subtitle={t('subtitle', {
                    type: state.campaign.incentives.voucher.type,
                    value: currencyFormatter.format(state.campaign.incentives.voucher.value)
                })}
            />
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Video))
