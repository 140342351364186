// @flow
import React from 'react'
import cn from 'classnames'

import { Store } from 'Store'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes'

type Props = {
    make: string,
    model: string,
    year?: number,
    angle: 'front_34' | 'back_34' | 'side',
    size: 'xs' | 's' | 'm' | 'l' | 'xl',
    color: string,
    fallbackImage: string,
    theme: Any
}

function getVehicleImageUrl (make: string, model: string, year: ?number, color: string, angle: string = 'front_34', size: string = 'l') {
    return `${process.env.REACT_APP_VEHICLE_IMAGES_BASE_URL}${make}.${model}.${year || 'latest'}.${angle}.${color}.${size}.png`
}

const VehicleModelImage = (props: Props) => {
    const { state: { campaign } } = React.useContext(Store)
    let [loaded, setLoaded] = React.useState(false)

    let imageSrc = getVehicleImageUrl(props.make, props.model, props.year || null, props.color, props.angle, props.size)

    const useDefaultVehicleImage = React.useMemo(() => {
        return campaign.models.every(model => model.name !== props.model && model.make !== props.make)
    }, [campaign, props.make, props.model])

    if (useDefaultVehicleImage) {
        imageSrc = props.fallbackImage
    }

    const handleImageLoad = React.useCallback(() => {
        setLoaded(true)
    }, [])

    return (
        <div className={cn(styles.wrapper, { [styles.loaded]: loaded })}>
            <img src={imageSrc} alt="" onLoad={handleImageLoad}/>
            <Loader color={props.theme.loaderColor}/>
        </div>
    )
}

const Loader = (props: { color: string }) => {
    return <div className={styles.loader} style={{ borderTopColor: props.color }}/>
}

export default withTheme(supportedThemes)(VehicleModelImage)
