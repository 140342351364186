// @flow
import React, { Component } from 'react'
import cn from 'classnames'
import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

const Checkmark = ({ color }) => (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <circle fill={color} cx="10" cy="10" r="10"></circle>
            <polyline stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" points="6.25 10.651102 9.44443979 13.7982178 14.276123 6.25"></polyline>
        </g>
    </svg>
)

type Props = {
    step: number
};

type StepProps = {
    t: Function,
    theme: { [string]: mixed },
    step: number,
    currentStep: number,
}

const Step = ({ t, theme, step, currentStep }: StepProps) => {
    const completed = currentStep > step
    const current = currentStep === step

    const classes = cn({ [styles.current]: current, [styles.completed]: completed })

    const isActive = current ? { color: theme.activeColor } : {}
    const checkmark = completed ? <Checkmark color={theme.checkmarkColor} /> : null

    return (
        <div className={styles.stepLabel}>
            {checkmark}
            <span className={classes} style={{ color: theme.inactiveColor, ...isActive }}>{t(`step${step}`)}</span>
        </div>
    )
}

class Steps extends Component<Props> {
    static defaultProps = {
        step: 1
    }

    render () {
        const { t, theme, step } = this.props

        return (
            <div className={styles.container}>
                <div className={styles.stepLabels}>
                    {[1, 2, 3].map(stepNumber => <Step key={stepNumber} step={stepNumber} currentStep={step} t={t} theme={theme} />)}
                </div>

                <div
                    className={styles.bar}
                    style={{ backgroundColor: theme.stepsBarInactiveBg }}
                >
                    <div
                        className={ styles.barActiveBlock }
                        style={{ left: `${this.props.step / 3 * 100 - 33}%`, backgroundColor: theme.stepsBarActiveBg }}
                    ></div>
                </div>
            </div>
        )
    }
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Steps))
