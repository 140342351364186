// @flow
import React, { Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

import { Store } from './Store'
import { AppRoute } from 'util/routerHelpers.js'
import { fetchCampaignAndLead } from './Actions'

import Step1 from './pages/Step1'
import Step2 from './pages/Step2'
import Success from './pages/Success'
import Welcome from './pages/Welcome'

import CampaignNotFound from './components/CampaignNotFound'
import NoLayout from './layouts/NoLayout'

const IS_CAMPAIGN_URL = /^\/.+$/.test(window.location.pathname)

export default function App () {
    const { state, dispatch } = React.useContext(Store)

    React.useEffect(() => {
        if (IS_CAMPAIGN_URL && state.campaign === null && state.errors.campaignNotFound !== true) {
            fetchCampaignAndLead(dispatch)
        }

        if (state.campaign) {
            document.title = `${state.campaign.name}`
        }
    })

    if (state.errors.campaignNotFound) {
        return <CampaignNotFound />
    }

    if (IS_CAMPAIGN_URL && (state.campaign === null || state.lead === null)) {
        return <h1>Loading</h1>
    }

    return (
        <Suspense fallback={<div>loading...</div>}>
            <BrowserRouter>
                <Switch>
                    <AppRoute exact path="/" layout={NoLayout} component={Welcome}/>
                    <AppRoute exact path="/:url" component={Step1}/>
                    <AppRoute exact path="/:url/step-2" component={Step2}/>
                    <AppRoute exact path="/:url/success" component={Success}/>
                </Switch>
            </BrowserRouter>
        </Suspense>
    )
}
