// @flow
import * as React from 'react'

import { Store } from 'Store.js'
import { withTranslation, Trans } from 'react-i18next'
import { Currency, Percent } from 'i18n/NumberFormatter'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

function CashbackAndRates (props) {
    const { theme, incentives } = props
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency('CAD', state.locale)

    return (
        <div className={styles.container} style={{ backgroundColor: theme.background }}>
            <div className={styles.cashBack}>
                <span className={styles.cashBackTitle}>
                    <Trans i18nKey="cash_back">
                        Combine with <strong>Cash Incentives</strong> up to
                    </Trans>
                </span>
                <span className={styles.cashBackValue} style={{ color: theme.color }}>{currencyFormatter.format(incentives.cashBack)}</span>
            </div>

            <div className={styles.rates}>
                <span className={styles.ratesTitle}>
                    <Trans i18nKey="rates">
                        Don't forget about our <strong>Rates</strong> as low as
                    </Trans>
                </span>
                <span className={styles.ratesValue} style={{ color: theme.color }}>{Percent.format((Number.parseFloat(incentives.interestRate).toPrecision(3) / 100) || 0)}</span>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(CashbackAndRates))
