// @flow
import * as React from 'react'

import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import { Currency } from 'i18n/NumberFormatter'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

import Modal from 'components/Modal'
import MainLogo from '../MainLogo'
import PlayButton from 'components/PlayButton'
import { Store } from 'Store'
import VideoPlayer from 'components/VideoPlayer'

function Hero (props) {
    const [ isModalOpen, setIsModalOpen ] = React.useState(false)
    const videoPlayer = React.useRef()
    const { t, theme } = props
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency('CAD', state.locale)

    const playVideo = () => {
        videoPlayer.current.play()
    }

    const scrollDown = (e) => {
        e.stopPropagation()

        props.onScrollDown && props.onScrollDown()
    }

    return (
        <div className={styles.container}>

            <div onClick={() => setIsModalOpen(true)} className={styles.thumbnail} style={{ backgroundImage: `url(${theme.background})` }}>
                <MainLogo logoClassName={styles.logo} title={state.campaign.client.name} />

                <div className={styles.title} style={{ color: theme.color }}>{t('title', { name: state.lead.fields.firstName })}</div>
                <div className={styles.subtitle} style={{ color: theme.color }}>
                    {t('subtitle', {
                        type: state.campaign.incentives.voucher.type,
                        value: currencyFormatter.format(state.campaign.incentives.voucher.value)
                    })}
                </div>

                <div className={styles.playButton}>
                    <PlayButton fill={theme.playButtonBackground} />
                </div>

                <div className={styles.scrollDown}>
                    <div onClick={scrollDown}>
                        <p className={styles.scrollDownText} style={{ color: theme.primaryColor }}>{t('scroll_down')}</p>

                        <svg width={119 / 16 + 'em'} viewBox="0 0 119 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                                <polyline stroke={theme.primaryColor} strokeWidth="2" points="1 1 59.5 18 118 1"></polyline>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>

            <Modal afterOpen={playVideo} isOpen={isModalOpen} onCancel={() => setIsModalOpen(false)}>
                <VideoPlayer src={state.campaign.videos.sidebar} ref={videoPlayer} />
            </Modal>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Hero))
