// @flow
import React from 'react'
import { formatDateTime, FormatType } from 'i18n/DateFormat'

export function absoluteTimeString (date, dateFormat, timeFormat, includeWeekday) {
    try {
        return formatDateTime(date, dateFormat, timeFormat, includeWeekday)
    } catch (err) {
        console.error(err)
        return null
    }
}

export default function AbsoluteTime (props: {
    date: Date,
    dateFormat?: FormatType,
    timeFormat?: FormatType,
    noContainer?: boolean,
}) {
    const date = formatDateTime(props.date, props.dateFormat, props.timeFormat)
    try {
        return (
            props.noContainer ? <>{date}</> : <span>{date}</span>
        )
    } catch (err) {
        console.error(err)
        return (<span>[[ ERROR ]]</span>)
    }
}
