/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import { useEffect, useRef } from 'react'

export default function useInterval (callback: Function, delay: number, limit: number, inputs: []) {
    let limitCounter = 0
    const id = useRef()
    const savedCallback = useRef()
    inputs.push(delay)

    useEffect(() => {
        savedCallback.current = callback
    })

    useEffect(() => {
        function tick () {
            limitCounter++
            savedCallback.current()

            if (limitCounter >= limit) {
                clearInterval(id.current)
            }
        }

        if (delay !== null) {
            id.current = setInterval(tick, delay)
            return () => clearInterval(id)
        }
    }, inputs)
}
