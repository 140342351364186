// @flow
import React from 'react'

import styles from './styles.module.scss'

type Props = {
    src: string
}

function Index (props: Props, ref) {
    const videoTag = React.useRef()

    React.useImperativeHandle(ref, () => ({
        play: () => {
            videoTag.current.muted = true
            videoTag.current.play()

            setTimeout(() => { videoTag.current.muted = false }, 100)
        }
    }))

    return (
        <div className={styles.wrapper}>
            <video ref={videoTag} controls>
                <source src={props.src} type="video/mp4" />
            </video>
        </div>
    )
}

export default React.forwardRef(Index)
