
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns3c15f6a575f555c64ac0fb2118321d31en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '3c15f6a575f555c64ac0fb2118321d31', flattenObject(ns3c15f6a575f555c64ac0fb2118321d31en_US), true, true);
        
            import ns3c15f6a575f555c64ac0fb2118321d31en from './en.yaml'
            i18n.addResourceBundle('en', '3c15f6a575f555c64ac0fb2118321d31', flattenObject(ns3c15f6a575f555c64ac0fb2118321d31en), true, true);
        
        export default '3c15f6a575f555c64ac0fb2118321d31';
    