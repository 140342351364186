// @flow
import * as React from 'react'

import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

import Incentives from 'components/Incentives'
import MessageIncentive from 'components/MessageIncentive'
import VideoThumbnail from 'components/VideoThumbnail'
import MediaQuery from 'react-responsive'
import SidebarHeader from '../Header'
import { Store } from 'Store'

import incentivesBackground from './images/incentives_background.png'

function SidebarIncentives (props) {
    const { t, theme } = props
    const { state } = React.useContext(Store)

    return (
        <div className={styles.container}>
            <MediaQuery minWidth={1201}>
                <SidebarHeader title={state.campaign.client.name} />
            </MediaQuery>

            <MediaQuery minWidth={1024} maxWidth={1200}>
                <VideoThumbnail
                    className={styles.videoThumbnail}
                    background={theme.videoBackground}
                    title={t('video_text')}
                />
            </MediaQuery>

            <Incentives car={theme.car} background={incentivesBackground} />

            <MediaQuery minWidth={1201}>
                <MessageIncentive />
            </MediaQuery>

        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(SidebarIncentives))
