import * as React from 'react'
import cn from 'classnames'
import styles from './styles.module.scss'
import MainLogo from 'components/MainLogo'

export default function Header (props) {
    return (
        <div className={cn(styles.container, props.className)} style={props.style}>
            <MainLogo title={props.title} />
        </div>
    )
}
