// @flow
import * as React from 'react'
import cn from 'classnames'
import { Redirect } from 'react-router-dom'

import * as Sidebar from 'components/Sidebar'
import Hero from 'components/Hero'
import MessageIncentive from 'components/MessageIncentive'
import Incentives from 'components/Incentives'
import MediaQuery from 'react-responsive'
import LeadGenForm from 'components/LeadGenForm/Step1/Step1'

import styles from './styles.module.scss'
import { Store } from 'Store'
import { updateLead } from 'Actions'

type Props = {
    location: Location
}

export default (props: Props) => {
    const containerRef = React.useRef()
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)

    if (submitted) {
        return <Redirect to={`${props.location.pathname}/step-2${props.location.search}`} push />
    }

    const scrollDown = () => {
        containerRef.current.scrollIntoView({ behavior: 'smooth', inline: 'nearest' })
    }

    return (
        <>
            <Hero onScrollDown={scrollDown} />

            <div ref={containerRef} className={styles.container}>
                <div className={cn(styles.half, styles.sidebarContainer)}>
                    <MediaQuery minWidth={1200}>
                        <Sidebar.Video />
                    </MediaQuery>

                    <MediaQuery minWidth={1024} maxWidth={1200}>
                        <MessageIncentive />
                    </MediaQuery>

                    <MediaQuery maxWidth={1200}>
                        <Incentives />
                    </MediaQuery>

                </div>

                <div className={cn(styles.half, styles.formContainer)}>
                    <LeadGenForm
                        onSubmit={async (data, done) => {
                            try {
                                let lead = {
                                    fields: data,
                                    isComplete: false
                                }
                                await updateLead(dispatch, null, lead)
                                setSubmitted(true)
                                window.scrollTo(0, 0)
                            } catch (err) {
                                alert('Oops something went wrong')
                                console.error(err)
                                done(err)
                            }
                        }}
                        initialValues={state.lead ? state.lead.fields : {}}
                    />
                </div>
            </div>
        </>
    )
}
