// @flow
import * as React from 'react'

import { Store } from 'Store'
import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'
import styles from './styles.module.scss'

import transDomain from './translations/index.translations'

import VoucherValue from 'components/VoucherValue'
import CashbackAndRates from 'components/CashbackAndRates'
import VehicleModelImage from 'components/VehicleModelImage'

function Incentives (props) {
    const { state: { campaign, lead } } = React.useContext(Store)
    const { t, theme } = props

    return (
        <div className={styles.inner} style={{ backgroundImage: `url(${props.background || theme.background})` }}>
            <div className={styles.showcase}>
                <VehicleModelImage
                    make={lead.fields.currentVehicleMake}
                    model={lead.fields.currentVehicleModel}
                    color={theme.vehicleModelColor}
                    angle='front_34'
                    size='l'
                    fallbackImage={theme.car}
                />
            </div>

            <div className={styles.title}>{t('title')}</div>

            <div className={styles.incentivesWrapper}>
                <VoucherValue voucher={campaign.incentives.voucher}/>
                <CashbackAndRates incentives={campaign.incentives.manufacturer}/>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Incentives))
