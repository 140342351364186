// @flow
import * as React from 'react'
import { Redirect } from 'react-router-dom'
import cn from 'classnames'

import { Store } from 'Store'

import SuccessInfo from 'components/SuccessInfo'
import * as Sidebar from 'components/Sidebar'

import styles from './styles.module.scss'

export default (props) => {
    const { state } = React.useContext(Store)

    if (!state.lead.isComplete) {
        return <Redirect to={`${props.location.pathname.replace('/success', '')}${props.location.search}`} push/>
    }

    return (
        <div className={styles.container}>
            <div className={cn(styles.half, styles.voucherContainer)}>
                <Sidebar.Voucher />
            </div>

            <div className={cn(styles.half, styles.formContainer)}>
                <SuccessInfo />
            </div>
        </div>
    )
}
