// @flow
import * as React from 'react'

import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import MainLogo from 'components/MainLogo'
import VoucherSvg from 'components/Voucher'
import { Store } from 'Store'
import { sendAutoresponder } from 'Actions'
import { svgToImage } from '@shift-marketing/event-websites-helper'
import useInterval from 'hook/useInterval'

function Voucher (props) {
    const { theme } = props
    const { state, dispatch } = React.useContext(Store)
    const voucherRef = React.useRef(null)

    useInterval(async () => {
        if (voucherRef.current && state.autoresponderShouldBeSent) {
            await svgToImage(voucherRef.current, async blob => {
                await sendAutoresponder(dispatch, `${state.campaign.incentives.voucher.name}.png`, blob)
            })
        }
    }, state.autoresponderShouldBeSent ? 1000 : null, 10, [state.autoresponderShouldBeSent])

    return (
        <div className={styles.container} style={{ background: `${theme.containerBg}` }}>
            <div className={styles.radialEffectContainer}>
                <div className={styles.radialEffect} style={{ background: `${theme.radialBg}` }}/>
            </div>
            <MainLogo title={state.campaign.client.name} style={{ marginBottom: '3em', position: 'relative' }}/>

            <div className={styles.voucher}>
                <VoucherSvg width='598px' ref={voucherRef}/>
            </div>

            {theme.cars &&
            <div className={styles.cars}>
                <img src={theme.cars} alt=""/>
            </div>
            }
        </div>
    )
}

export default withTheme(supportedThemes)(Voucher)
