// @flow
import * as React from 'react'

import { Store } from 'Store'

import TopBar from 'components/TopBar'
import Footer from 'components/Footer'

import styles from './MainLayout.module.scss'

const MainLayout = props => {
    const { state } = React.useContext(Store)

    return (
        <div className={styles.main}>
            <TopBar date={state.campaign.expiryDate} />

            <div className={styles.innerContainer}>
                {props.children}
            </div>

            <Footer/>
        </div>
    )
}

export default MainLayout
