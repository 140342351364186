// @flow
import * as React from 'react'

import { Store } from 'Store.js'
import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'
import { wrapText } from '../Form/Helpers'

type ImagePath = string;
type Props = {
  theme: {
    background: string,
    color: string,
    logo: ImagePath,
  }
}

function Footer (props: Props) {
    const { state } = React.useContext(Store)
    const { campaign } = state
    const { theme } = props

    const terms = wrapText(
        campaign.incentives.voucher.terms.replace('[dealership_name]', campaign.client.name),
        180
    )
    let address = { ...state.campaign.client.address }
    let formattedAddress = null

    if (state.campaign.client.address) {
        delete address.country
        formattedAddress = Object.values(address).filter(Boolean).join(', ')
    }

    return (
        <div className={styles.container} style={{ background: theme.background, color: theme.color }}>
            <div className={styles.innerContainer}>
                <img className={styles.logo} src={theme.logo} alt='' />

                <p className={styles.dealershipInfo}>
                    {state.campaign.client.name}

                    { formattedAddress ? ` | ${formattedAddress}` : '' }

                    {
                        state.campaign.contactInfo.phoneNumber &&
                            ` | ${state.campaign.contactInfo.phoneNumber}`
                    }
                </p>

                <p className={styles.disclaimer}>{terms}</p>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Footer))
