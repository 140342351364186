// @flow
import * as React from 'react'
import cn from 'classnames'

import { Currency } from 'i18n/NumberFormatter'
import { Store } from 'Store'

import { useTranslation, Trans } from 'react-i18next'
import transDomain from './translations/index.translations'

import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'

import * as Form from 'components/Form'

import styles from './styles.module.scss'
import { patchLead, sendAppointmentDate, sendClientMessage } from 'Actions'
import { AppointmentWidgetWrapper } from '@shift-marketing/event-websites-chatbot'
import '@shift-marketing/event-websites-chatbot/dist/main.css'
import useTimeout from 'hook/useTimeout'

function SuccessInfo (props) {
    const { theme } = props
    const { state, dispatch } = React.useContext(Store)
    const [isUpdating, setIsUpdating] = React.useState(false)
    const { t } = useTranslation(transDomain)
    const currencyFormatter = Currency('CAD', state.locale)
    const [appointmentWidget, setAppointmentWidget] = React.useState(false)

    const changeEmailAddress = (e) => {
        e.preventDefault()
        setIsUpdating(true)
    }

    const updateEmailAddress = async (e) => {
        e.preventDefault()

        setIsUpdating(false)

        await patchLead(dispatch, null, { email: e.target.elements['email'].value }, true)
    }

    useTimeout(() => {
        if (state.campaign.hasOwnProperty('incentives') && state.campaign.incentives.hasOwnProperty('onlineBooking') && !state.campaign.expired) {
            let localTimezone
            try {
                localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            } catch {
                localTimezone = state.campaign.timezone
            }
            setAppointmentWidget(
                <AppointmentWidgetWrapper
                    onlineBooking={state.campaign.incentives.onlineBooking}
                    timezone={localTimezone}
                    lead={state.lead}
                    clientName={state.campaign.client.name}
                    expiryDate={state.campaign.expiryDate}
                    colors={{
                        primary: theme.primary,
                        secondary: theme.secondary
                    }}
                    actions={{
                        sendAppointmentDate,
                        sendClientMessage
                    }}
                    currencyFormatter={Currency(state.currency, state.locale)}
                />
            )
        }
    }, 5000)

    return (
        <>
            {appointmentWidget}
            <div className={styles.container} style={{ background: theme.background }}>
                <div className={styles.congratsContainer} style={{ color: theme.secondary, ...theme.congratsContainerStyles || {} }}>
                    <div className={styles.congratsTitle}>{t('congrats_title', { name: state.lead.fields.firstName })}</div>

                    <div className={styles.congratsValue} style={{ color: theme.primary }}>
                        {currencyFormatter.format(state.lead.incentives.voucher.value)}
                    </div>

                    <p className={styles.congratsSubtitle}>
                        {t('congrats_subtitle', {
                            voucher: state.campaign.incentives.voucher.name
                        })} {`${state.lead.fields.newVehicleMake} ${state.lead.fields.newVehicleModel}`}
                    </p>
                </div>

                <div className={styles.finalDetails}>
                    <p>{t('final_details', { vehicle: `${state.lead.fields.newVehicleMake} ${state.lead.fields.newVehicleModel}` })}</p>

                    <p>{t('confirmation_email')}</p>

                    <div className={styles.updateEmail}>
                        <form onSubmit={updateEmailAddress}>
                            <Form.Field disabled={!isUpdating} defaultValue={state.lead.fields.email} type='email' name='email'/>
                            <button
                                type="submit"
                                style={{ background: theme.secondary }}
                                className={cn({ [styles.activeButton]: isUpdating })}
                            >{t('update')}</button>
                        </form>
                    </div>

                    <p className={styles.updateEmailLabel} style={{ color: theme.secondary }}>
                        <Trans i18nKey="change_email">
                            Not the correct email address? <a href="/" onClick={changeEmailAddress} style={{ color: theme.primary }}>Click Here</a> to change.
                        </Trans>
                    </p>
                </div>

                <div className={styles.cars}>
                    <img src={theme.cars} alt=''/>
                </div>
            </div>
        </>
    )
}

export default withTheme(supportedThemes)(SuccessInfo)
